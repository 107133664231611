/* ************************
   06.2: Chat 
   ********************* */

   .aside-body, .aside-header {
      img {
         @extend .radius;
      }
   }

   .message {
      padding: 20px 15px;
      @extend .trans3;
      position: relative;
      &:after {
         @extend .ba;
         left: 15px;
         width: calc(100% - 30px);
         height: 1px;
         background-color: #f5f5f5;
         bottom: 0;
      }
      h4 {
         @include IE {
            min-width: 120px;
         }
         span {
            position: relative;
            top: -2px;
         }
      }
      p {
         @extend .text_color;
         @extend .trans3;
      }
      .figure {
         position: relative;
         margin-right: 14px;
      }
      &:hover, &.active {
         @extend .box-shadow2;
         p {
            //text-shadow: 0 1px 1px $text_color;
            @extend .bold;
            &.time {
               @extend .black;
               //text-shadow: none;
            }
         }
      }
   }

   /* Chat Header */
   .chat-header {
      padding: 10px 30px;
      padding-top: 20px;
      @include mobileMd {
         padding: 10px;
      }
   }
   .chat-header-right {
      .video-call {
         a {
            line-height: 1;
         }
      }
   }

   .video-call, .audio-call {
      padding: 10px;
      width: 40px;
      height: 40px;
      background-color: #E9E7FF;
      @extend .radius;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   /* Chat Body */
   .chat-body {
      padding: 30px;
      height: calc(100vh - 383px);
      position: relative;
      top: 0px;
      @include mobileMd {
         padding: 15px 10px;
      }
   }

   .chat-messages {
      min-height: 100%;
   }

   .message-item {
      display: flex;
      margin-bottom: 30px;
      .avatar {
         position: relative;
      }
      .bubble {
         p {
            background-color: #f5f5f5;
            @extend .radius-10;
            margin-bottom: 10px;
            display: inline-block;
            padding: 7px 20px;
         }
      }
      &.friend {
         .bubble {
            p {
               background-color: #bee3fb;;
            }
         }
      }
      &.me {
         .bubble {
            p {
               background-color: #FFF4E6;
            }
         }
      }
   }


   /* Chat Footer */
   .chat-footer {
      padding: 30px;
      @include mobileMd {
         flex-wrap: wrap;
         padding: 10px;
      }

      .voice-msg,
      .attach-file,
      .submit {
         @extend .video-call;
         width: 50px;
         height: 50px;
      }
      div .submit {
         @extend .c1-bg;
         svg {
            position: relative;
            left: 1px;
         }
      }
      form {
         line-height: 1;
         @include mobileMd {
            order: -1;
            margin: 0;
            width: 100%;
            margin-bottom: 10px;
         }
         &.radius-10 {
            textarea {
               border-radius: 10px;
            }
         }
         textarea {
            height: 50px !important;
         }
      }
   }


   /* Search box */
   #search-tab {
      ~ #search-box {
         position: absolute;
         width: 100%;
         height: 100%;
         left: 0;
         top: 0;
         input {
            @include vertical-gradient(#ff6d00, #6045E2);
            width: 100%;
            height: 100%;
            @extend .border-0;
            padding: 5px 10px;
            padding-right: 50px;
            @extend .white;
            &::placeholder {
               color: $white !important;
            }
         }
         .search-box-close {
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            cursor: pointer;
         }
      }
   }