//Default Colors
$c1: #6045E2;
$c2: #ff6d00;
$c3: #4ea7fb;
$c4: #aeaeae;
$c5: #f0f0f0;
$text_color: #727272;
$white: #ffffff;
$black: #333333;


// Fonts
$pt_sans: 'PT Sans', sans-serif;

// Font Weight
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$bolder: 900;